define("ember-google-maps/components/g-map/circle", ["exports", "ember-google-maps/components/g-map/marker", "ember-google-maps/utils/helpers"], function (_exports, _marker, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Circle extends _marker.default {
    get name() {
      return 'circles';
    }

    get newOptions() {
      var _this$options;

      (_this$options = this.options).radius ?? (_this$options.radius = 500);

      if (!this.args.center) {
        this.options.center = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }

      return this.options;
    }

    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.Circle(options);
    }

  }

  _exports.default = Circle;
});