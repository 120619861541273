define("ember-google-maps/components/g-map/marker", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/typical-map-component", "ember-google-maps/utils/helpers"], function (_exports, _component, _templateFactory, _typicalMapComponent, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    infoWindow=(component "g-map/info-window" getContext=@getContext target=this.mapComponent))}}
  */
  {
    "id": "sOlaNRF/",
    "block": "[[[18,2,[[28,[37,1],null,[[\"infoWindow\"],[[50,\"g-map/info-window\",0,null,[[\"getContext\",\"target\"],[[30,1],[30,0,[\"mapComponent\"]]]]]]]]]]],[\"@getContext\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-google-maps/components/g-map/marker.hbs",
    "isStrictMode": false
  });

  class Marker extends _typicalMapComponent.default {
    get name() {
      return 'markers';
    }

    get newOptions() {
      if (!this.args.position) {
        this.options.position = (0, _helpers.toLatLng)(this.args.lat, this.args.lng);
      }

      return this.options;
    }

    newMapComponent() {
      let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      return new google.maps.Marker(options);
    }

  }

  _exports.default = Marker;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Marker);
});