define("ember-google-maps/components/g-map/overlay", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component", "@glimmer/tracking", "@ember/object", "@ember/object/internals", "ember-google-maps/utils/helpers"], function (_exports, _component, _templateFactory, _mapComponent, _tracking, _object, _internals, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (has-block)}}
    {{#if this.container}}
      {{#in-element this.container}}
        <div id={{this.id}} ...attributes {{g-map/did-insert this.getOverlay}}>{{yield}}</div>
      {{/in-element}}
    {{/if}}
  {{/if}}
  */
  {
    "id": "s5duYp0a",
    "block": "[[[41,[48,[30,2]],[[[41,[30,0,[\"container\"]],[[[40,[[[1,\"      \"],[11,0],[16,1,[30,0,[\"id\"]]],[17,1],[4,[38,4],[[30,0,[\"getOverlay\"]]],null],[12],[18,2,null],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,0,[\"container\"]]],null]]],[]],null]],[]],null]],[\"&attrs\",\"&default\"],false,[\"if\",\"has-block\",\"in-element\",\"-in-el-null\",\"g-map/did-insert\",\"yield\"]]",
    "moduleName": "ember-google-maps/components/g-map/overlay.hbs",
    "isStrictMode": false
  });

  let OverlayView = (_class = class OverlayView extends _mapComponent.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "id", `ember-google-maps-overlay-${(0, _internals.guidFor)(this)}`);

      _initializerDefineProperty(this, "container", _descriptor, this);
    }

    get name() {
      return 'overlays';
    }

    get zIndex() {
      return this.args.zIndex ?? 'auto';
    }

    get paneName() {
      return this.args.paneName ?? 'overlayMouseTarget';
    }

    get position() {
      let {
        lat,
        lng,
        position
      } = this.args;
      return position ?? (0, _helpers.toLatLng)(lat, lng);
    }

    setup() {
      let Overlay = new google.maps.OverlayView();

      Overlay.onAdd = () => this.onAdd();

      Overlay.onRemove = () => this.onRemove();

      Overlay.draw = () => this.draw(); // Make sure we don’t run “draw” before Google Maps has done so first.


      Overlay.didDraw = false;
      Overlay.setMap(this.map); // Explicitly track options here, as the Google Maps performs the setup
      // asynchronously.

      return [Overlay, Object.values(this.options)];
    } // TODO: support changing pane?


    update(overlay) {
      if (overlay.didDraw) {
        overlay.draw();
      }
    }

    onAdd() {
      let panes = this.mapComponent.getPanes();
      this.targetPane = panes[this.paneName];
      this.targetPane.appendChild(this.overlayElement);
      this.addEventsToMapComponent(this.overlayElement, this.events, this.publicAPI);
    }

    draw() {
      var _this$mapComponent;

      let {
        position,
        zIndex
      } = this;
      let overlayProjection = this.mapComponent.getProjection();
      let point = overlayProjection.fromLatLngToDivPixel(position);
      this.overlayElement.style.cssText = `
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      z-index: ${zIndex};
      transform: translateX(${point.x}px) translateY(${point.y}px);
    `;
      (_this$mapComponent = this.mapComponent).didDraw || (_this$mapComponent.didDraw = true);
    }

    onRemove() {
      let parentNode = this.overlayElement.parentNode;

      if (parentNode) {
        parentNode.removeChild(this.overlayElement);
      }
    }

    teardown() {
      var _this$mapComponent2;

      // This calls onRemove.
      (_this$mapComponent2 = this.mapComponent) === null || _this$mapComponent2 === void 0 ? void 0 : _this$mapComponent2.setMap(null);
      this.overlayElement = null;
      this.container = null;
    }

    getOverlay(element) {
      this.overlayElement = element;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "container", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      var _window, _window$document;

      return (_window = window) === null || _window === void 0 ? void 0 : (_window$document = _window.document) === null || _window$document === void 0 ? void 0 : _window$document.createDocumentFragment();
    }
  }), _applyDecoratedDescriptor(_class.prototype, "getOverlay", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "getOverlay"), _class.prototype)), _class);
  _exports.default = OverlayView;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, OverlayView);
});