define("ember-google-maps/component-managers/map-component-manager", ["exports", "@ember/component", "@ember/application", "@ember/service", "@ember/destroyable", "@ember/debug", "@ember/test-waiters", "ember-google-maps/utils/options-and-events", "ember-google-maps/effects/tracking"], function (_exports, _component, _application, _service, _destroyable, _debug, _testWaiters, _optionsAndEvents, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MapComponentManager = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let testWaiter = (0, _testWaiters.buildWaiter)('ember-google-maps:map-component-waiter');
  let MapComponentManager = (_class = class MapComponentManager {
    get google() {
      return this.googleMapsApi.google;
    }

    get isFastBoot() {
      var _this$fastboot;

      return ((_this$fastboot = this.fastboot) === null || _this$fastboot === void 0 ? void 0 : _this$fastboot.isFastBoot) ?? false;
    }

    constructor(owner) {
      _initializerDefineProperty(this, "googleMapsApi", _descriptor, this);

      _defineProperty(this, "capabilities", (0, _component.capabilities)('3.13', {
        asyncLifecycleCallbacks: false,
        destructor: true,
        // The update hook updates every single component in the tree, which is slow
        // as molasses.
        updateHook: false,
        createArgs: true,
        prepareArgs: true
      }));

      this.owner = owner;
      (0, _application.setOwner)(this, owner);
      this.fastboot = owner.lookup('service:fastboot');
    }

    createComponent(Class, args) {
      let optionsTracker = new _optionsAndEvents.OptionsAndEvents(args.named);
      let {
        options,
        events
      } = optionsTracker;
      let component = new Class(this.owner, args.named, options, events);

      if (!this.isFastBoot) {
        // TODO: What happens when we fail to load the API?
        this.google.then(() => {
          this.setupMapComponent(component);
        });
      }

      return component;
    }

    destroyComponent(component) {
      if (component.mapComponent) {
        component === null || component === void 0 ? void 0 : component.teardown(component.mapComponent);
      }

      (0, _destroyable.destroy)(component);
    }

    getContext(component) {
      return component ?? {};
    }

    setupMapComponent(component) {
      (false && !(component.setup) && (0, _debug.assert)('Each map component needs to have a `setup` method.', component.setup));
      let token = testWaiter.beginAsync();
      let hasUpdate = typeof component.update === 'function';
      let effect, mapComponent, trackThisInstead;

      if (hasUpdate) {
        effect = (0, _tracking.setupEffect)(() => {
          if (mapComponent === undefined) {
            mapComponent = component.setup(component.options, component.events);

            if (mapComponent.length) {
              [mapComponent, trackThisInstead] = mapComponent;
            }

            component.mapComponent = mapComponent;
          } else {
            component.update(mapComponent, component.options);
          }

          testWaiter.endAsync(token);
          return trackThisInstead ?? mapComponent;
        });
      } else {
        effect = (0, _tracking.setupEffect)(() => {
          // Teardown the previous map component if it exists
          if (mapComponent) {
            component.teardown(mapComponent);
          }

          mapComponent = component.setup(component.options, component.events);
          component.mapComponent = mapComponent;
          testWaiter.endAsync(token);
          return mapComponent;
        });
      } // Destroy effects when the component is destroyed.


      if (!(0, _destroyable.isDestroyed)(component) && !(0, _destroyable.isDestroying)(component)) {
        (0, _destroyable.associateDestroyableChild)(component, effect);
      }

      return mapComponent;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "googleMapsApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.MapComponentManager = MapComponentManager;
});