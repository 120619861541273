define("ember-css-modules/templates/static-helpers-hack", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{local-class "hello"}}
  
  */
  {
    "id": "416qF1pF",
    "block": "[[[1,[28,[35,0],[\"hello\"],null]],[1,\"\\n\"]],[],false,[\"local-class\"]]",
    "moduleName": "ember-css-modules/templates/static-helpers-hack.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});