define("in-repo-pin-addon/components/g-map-addons/pin", ["exports", "@ember/component", "@ember/template-factory", "ember-google-maps/components/g-map/map-component"], function (_exports, _component, _templateFactory, _mapComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <GMap::Marker @lat="51.507568" @lng="-0.127762" @getContext={{@getContext}} />
  */
  {
    "id": "tTZhSSq1",
    "block": "[[[8,[39,0],null,[[\"@lat\",\"@lng\",\"@getContext\"],[\"51.507568\",\"-0.127762\",[30,1]]],null]],[\"@getContext\"],false,[\"g-map/marker\"]]",
    "moduleName": "in-repo-pin-addon/components/g-map-addons/pin.hbs",
    "isStrictMode": false
  });

  class Pin extends _mapComponent.default {
    get name() {
      return 'pin';
    }

    setup() {}

  }

  _exports.default = Pin;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Pin);
});